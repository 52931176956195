import React, { useState, useEffect } from "react";
import "./App.css";
import { ParallaxBanner } from "react-scroll-parallax";

import LazyLoad from 'react-lazyload';

import main_desktop from './backgrounds/main_page-d-p.webp';

import food_desktop from './backgrounds/food.webp';
import food_phone from './backgrounds/drink.webp';

import sidebar from './backgrounds/sidebar.svg';
import signature from './backgrounds/Judy_Signature_White.png';

import products_desktop from './backgrounds/products_D_F.webp'
import products_phone from './backgrounds/products.webp'

import portrait_desktop from './backgrounds/portrait_D_F.webp';
import portrait_phone from './backgrounds/portrait.webp';

import events_desktop from './backgrounds/events_D_F.webp'
import events_phone from './backgrounds/events.webp'

import prewedding_desktop from './backgrounds/prewedding_D_F.webp'
import prewedding_phone from './backgrounds/prewedding.webp'

import house_desktop from './backgrounds/home_D_F.webp';
import house_phone from './backgrounds/home.webp';

import monument_desktop from './backgrounds/monuments_D_F.webp';
import monument_phone from './backgrounds/monuments.webp';

import extra_desktop from './backgrounds/extra_D_F.webp';
import extra_phone from './backgrounds/extra.webp';

const App = () => {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const element = document.getElementById('fade-in-text'); // Replace with your element's ID or reference

      if (element) {
        const { top } = element.getBoundingClientRect();
        const isVisible = top <= windowHeight * 0.75; // Adjust the visibility threshold as needed

        if (isVisible) {
          setIsScrolled(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 720);
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Attach event listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
    };
  }, []);

  let toggler = false;

  function toggleSidebar() {
    let sidebar = document.querySelector("#sidebar_content");
    if (toggler) {
      sidebar.style.display = "none";
    } else {
      sidebar.style.display = "block";
    }
    toggler = !toggler;
  }

  return (
    <div className="container-body">

      <div className="header">
        <div className="sidebar_wrapper" id="sidebar" onClick={toggleSidebar}>
          <img className="sidebar_svg" src={sidebar}></img>
          <div className="sidebar_content" id="sidebar_content">
            <a href="https://www.instagram.com/judyfinge/">Contact Me</a>
            <a href="https://view.judyfinge.com/food-and-drinks.php">Food & Drinks</a>
            <a href="https://view.judyfinge.com/products.php">Products</a>
            <a href="https://view.judyfinge.com/portrait.php">Portrait</a>
            <a href="https://view.judyfinge.com/event.php">Events</a>
            <a href="https://view.judyfinge.com/prewedding.php">Preweddings</a>
            <a href="https://view.judyfinge.com/house.php">Houses</a>
            <a href="https://view.judyfinge.com/monument.php">Monuments</a>
            <a href="https://view.judyfinge.com/extra.php">Extra</a>
          </div>
        </div>

        <div className="signature_wrapper">
          <img className="signature_image" src={signature}></img>
        </div>
      </div>

      <LazyLoad>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? "https://aucalled.sirv.com/Images/main_page-d-p.webp"
                : main_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          className="bg-container"
        >
          <div className="bg-container">
            <h1 className="">Judy finge</h1>
            <br />
            <h3 className="">Photography Porfolio</h3>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? food_phone
                : food_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>
              Food & drinks
            </h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/food-and-drinks.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? products_phone
                : products_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>Products</h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/products.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? events_phone
                : events_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',

          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>Events</h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/event.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? prewedding_phone
                : prewedding_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',

          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>Preweddings</h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/prewedding.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once >
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? house_phone
                : house_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',

          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>Houses</h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/house.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? monument_phone
                : monument_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',

          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>Monuments</h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/monument.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <LazyLoad height={500} offset={400} once>
        <ParallaxBanner
          layers={[
            {
              image: isSmallScreen
                ? extra_phone
                : extra_desktop,
              speed: -20
            },
            {
              speed: -10
            }
          ]}

          style={{
            height: isSmallScreen
              ? '450px'
              : '300px',
            backgroundSize: 'contain', // Set the background size to contain
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',

          }}

          className="bg-container"
        >
          <div className="bg-container">
            <h2 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}>Extra</h2>
            <br />
            <h4 id="fade-in-text" className={isScrolled ? 'fade-in' : ''}><a href="https://view.judyfinge.com/extra.php">Browse</a> &nbsp; &nbsp;→</h4>
          </div>
        </ParallaxBanner>
      </LazyLoad>

      <div class="footer">
        <div class="footer_text">
          <div class="footer_text_main">
            Copyright &copy; Judy Finge 2023
          </div>
          <div class="footer_text_secondary">
            <a class="footer_links" href="https://www.instagram.com/judyfinge/">Contact Me</a>
            <a class="footer_links" href="https://view.judyfinge.com/admin.php">Admin Login</a>
          </div>
        </div>

        <div class="footer_icon">
          <img src={signature} className="footer_icon_img" />
        </div>
      </div>

    </div>
  );
};

export default App;
